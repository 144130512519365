import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ServicesPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Services" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Services Offered
          </h2>
          {/* <div className="row">
          <div className="col-12">
            <div
              style={{
                padding: "1rem 0",
                textAlign: "center",
                background: "#1d1d1f",
              }}
            >
              12
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div
              style={{
                padding: "1rem 0",
                textAlign: "center",
                background: "#1d1d1f",
              }}
            >
              3
            </div>
          </div>
          <div className="col-9">
            <div
              style={{
                padding: "1rem 0",
                textAlign: "center",
                background: "#1d1d1f",
              }}
            >
              9
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div
              style={{
                padding: "1rem 0",
                textAlign: "center",
                background: "#1d1d1f",
              }}
            >
              6
            </div>
          </div>
          <div className="col-6">
            <div
              style={{
                padding: "1rem 0",
                textAlign: "center",
                background: "#1d1d1f",
              }}
            >
              6
            </div>
          </div>
        </div> */}
          <div className="row">
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Potraits</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Family</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Couples</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Fur Babies</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Real Estate</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Weddings</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#1d1d1f",
                }}
              >
                <h4>Events</h4>
                <ul>
                  <li>5 edited images</li>
                  <li>1 hour</li>
                </ul>
                <p style={{ fontSize: "1.5rem" }}>Starting at $49.99</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    smallPic: file(
      relativePath: { eq: "fabio-comparelli-696506-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medPic: file(relativePath: { eq: "sophia-valkova-30139-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    largePic: file(
      relativePath: { eq: "vladimir-malyutin-98174-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ServicesPage location={props.location} data={data} {...props} />
    )}
  />
)
